@import url('https://fonts.googleapis.com/css2?family=Diplomata+SC&family=Great+Vibes&family=Kenia&family=Roboto&family=Rubik+80s+Fade&family=Rubik+Vinyl&family=Shadows+Into+Light&family=Solitreo&display=swap');
  *{
            padding: 0;
            margin: 0;
            font-family: 'Roboto';
        }
        h1{
        font-weight: bold;
        }
        p{
            font-family: 'poppins';
        }
        /* Background styles */
        .bgimg {
            background-position: center;
            height: 888px;
            width: 600px;
            margin-left: 50px;
            margin-top: 30px;
        }
        
        /* Glassmorphism card effect */
        .card {
            backdrop-filter: blur(20px);
            background-color: rgb(82 84 85 / 25%);
            width: 600px;
            height: 888px;
        
        }
        #bgimage1
        {
            height: 325px;
            width: 700px;
        }
        .image2{
            height: 100px;
            border-radius: 50%;
            position: absolute;
            top: 318px;
          left: 2px;
        }
        .images {
          position: relative;
          top: 0;
          left: 0;
        }
        #image1 {
          position: relative;
          top: 0;
          left: 0;
        }
        .userdetails{
            padding-left: 110px;
        }
        .details{
            display: flex;
            padding: 24px;
        }
        .badges h1{
            text-align: center;
        }
        .badges img{
            padding: 5px 10px;
        }
        .details div{
            padding: 15px;
        }
        h1{
            font-size: 20px;
        }
        p{
            font-size: 18px;
            font-family: 'poppins';
        }
        .innerflex h1  {
            padding-top: 10px;
            font-family: 'bebas Neue';
            padding-bottom: 2px;
        
        }
        .innerflex div{
            padding: 5px;
        }
        select,input[type=date] {
            font-family: 'poppins';
            border: 1px solid rgb(214, 203, 203);
            background-color: rgba(225, 222, 222, 0.17);
            padding: 10px;
            border-radius: 4px;
        }
        .navbar img{
            height: 100px;
        }
        .navbar{
            display: flex;
            align-items: center;
            padding: 5px;
            justify-content: space-between;
        }
        #navbar{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #navbar li {
            list-style: none;
            padding: 0px 20px;
            position: relative;
            font-family: 'Bebas Neue';
            font-size: 25px;
            text-decoration: none;
            cursor: pointer;
        }
        #navbar a {
            font-family: 'Bebas Neue';
            font-size: 25px;
            color: #000;
            text-decoration: none;
        }
        #navbar li:hover,#navbar a:hover{
            color: #c31432;
            transition: 0.2s ease-in-out;
        }
        #navbar li:active,#navbar a:active{
            color: #1fe9ce;
        }
        #mobile {
            display: none;
            }
            .Form{
                margin-top: 30px;
            }
            .btn:hover{
                background-color: black;
            }
            .btn{
                background-color: rgba(213, 60, 53, 1);
                border-radius: 4px;
                padding: 10px 180px;
                font-family: 'Bebas Neue';
                font-size: 30px;
                color: #fff;
                border: none;
                margin-top:10px;
            }
            .home h1 , .container h1{
                font-family: 'Bebas Neue';
                font-size: 50px;
                font-weight: 500;
            }
            .home button{
                background-color: rgba(213, 60, 53, 1);
                border-radius: 17px;
                font-family: 'Bebas Neue';
                font-size: 30px;
                color: #fff;
                border: none;
                padding: 11px 17px;
                margin-top: 15px;
            
            }
            .home{
                margin-left: 30px;
                margin-top: 100px;
            }
            .container,.grid{
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            .footer{
                padding: 30px;
                margin-top:10px;
            }
            .footer h1{
                font-family: 'Bebas Neue';
                font-size: 36px;
                font-weight: 500;
            }
            .footer button{
                background-color: rgba(213, 60, 53, 1);
                border-radius: 5px;
                font-family: 'Bebas Neue';
                font-size: 30px;
                color: #fff;
                border: none;
                padding: 4px 20px;
                margin-left: 10px;
            }
            .join p {
                padding-left: 16px;
            }
            .rankcard h1,h2,h3 {
                 font-family: "Bebas Neue";
            }
            .meter {
                height: 20px;
                position: relative;
                background: #FFFFFF;
                border-radius: 25px;
                margin: 10px;
              }
            .meter > span {
                display: block;
                height: 100%;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                background: #E42753;
                position: relative;
                overflow: hidden;
              }
                      /* Background styles */
            .gameimg {
                background-image: url('../public/Rectangle\ 135.png');
                background-position: center;
                height: 800px;
                color: #fff;
                width: 734px;
            }
            
            .gamecard h1,p {
                font-family: 'poppins';
                font-weight: 500;
            }
            /* Glassmorphism gamecard effect */
            .gamecard {
                backdrop-filter: blur(20px);
                background-color: rgb(82 84 85 / 25%);
                width:734px;
                height: 800px;
            
            }
            #image2{
                height: 100px;
                border-radius: 50%;
                position: absolute;
              top: 270px;
              left: 2px;
            }
            .gameimage {
              position: relative;
              top: 0;
              left: 0;
            }
                                        